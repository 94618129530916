import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { sendHttpCall } from 'shareService/httpService';
import styles from './design.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SendNotification = (props) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [salesAdmins, setSalesAdmin] = useState([]);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [notificationDetails, setNotificationDetails] = useState({
    adminId: '',
    message: '',
    alertType: '' // new state for alert type
  });

  const handleChange = (field) => (e) => {
    const value = e.target ? e.target.value : e.value;
    setNotificationDetails(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const getSalesAdmins = () => {
    let url = `admin/users/SALES`;
    sendHttpCall('GET', url, null, props)
      .then((response) => {
        const formattedAdmins = [
          { label: 'SELF', value: 'self' },
          ...response.data.map((admin) => ({
            label: `${admin.name} (${admin.email_id})`,
            value: admin.admin_id
          })),
        ];
        setSalesAdmin(formattedAdmins);
      })
      .catch((error) => {
        console.log('Error fetching sales admins:', error);
        showSnackbar('Failed to fetch sales admins', 'error');
      });
  };

  const showSnackbar = (message, severity = 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    getSalesAdmins();
  }, []);

  const validateForm = () => {
    if (!notificationDetails.adminId || !notificationDetails.message || !notificationDetails.alertType) {
      showSnackbar('Please fill all fields', 'error');
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const finalNotificationDetails = {
        ...notificationDetails,
        adminId: notificationDetails.adminId === 'self' ? localStorage.getItem('admin_id') : notificationDetails.adminId
      };
      let url = 'notifications/send';
      sendHttpCall('POST', url, finalNotificationDetails, props)
        .then((response) => {
          showSnackbar('Notification sent successfully!', 'success');
        })
        .catch((error) => {
          console.log(error)
          showSnackbar(`${error.message}`, 'error');
        });
    }
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <h2 className={styles.title}>Send Notification</h2>
      <div className={styles.formRow}>
        <div className={styles.inputGroup}>
          <label>Send To <span className={styles.required}>*</span></label>
          <Select
            options={salesAdmins}
            value={salesAdmins.find(option => option.value === notificationDetails.adminId) || {}}
            onChange={handleChange('adminId')}
            className={styles.select}
            placeholder="Select Admin"
          />
        </div>
      </div>
      <div className={styles.inputGroup}>
        <label>Choose Notification Type <span className={styles.required}>*</span></label>
        <div className={styles.radioGroup}>
          <input type="radio" id="alert" name="alertType" value="alert" onChange={handleChange('alertType')} />
          <label htmlFor="alert">Alert</label>
          <input type="radio" id="info" name="alertType" value="info" onChange={handleChange('alertType')} />
          <label htmlFor="info">Info</label>
        </div>
      </div>
      <div className={styles.inputGroup}>
        <label>Message <span className={styles.required}>*</span></label>
        <textarea onChange={handleChange('message')} value={notificationDetails.message}></textarea>
      </div>

      <div className={styles.addBtn}>
        <button type="submit" className={styles.submitButton}>SEND</button>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default SendNotification;
