import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from '@mui/material';
import { sendHttpCall } from 'shareService/httpService';
import { GridOn, ViewAgenda, LinkRounded } from '@material-ui/icons';
import MaterialTable from 'material-table';
import ReactModal from 'react-modal';
import './style.scss';
import { Search } from '@material-ui/icons';
import { Link as MuiLink } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { Link } from 'react-router-dom';
import EditJobPost from './EditJobPost';
import EditJobPostV2 from './EditJobPostV2';
import JobCard from 'components/JobCard/JobCard';
import ReactSelect from 'react-select';
import Axios from 'axios';
import { esocialUrl } from 'config';
import Loader from 'components/Loader';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const planNames = {
  HIRER_ADD_ON_1: 'Bronze',
  HIRER_ADD_ON_2: 'Silver',
  HIRER_ADD_ON_3: 'Gold',
  HIRER_PREMIUM: 'Enterprise',
  HIRER_ADD_ON_4: 'Basic',
  HIRER_ADD_ON_5: 'Premium',
  HIRER_ADD_ON_6: 'Enterprise',
  HIRER_ADD_ON_7: 'Starter',
  HIRER_ADD_ON_8: 'Basic',
  HIRER_ADD_ON_9: 'Premium',
  HIRER_ADD_ON_10: 'Enterprise',

  HIRER_ADD_ON_10: 'Enterprise',

  HIRER_JOB_PLAN_1: 'Jobs Basic',

  HIRER_JOB_PLAN_2: 'Jobs Standard',

  HIRER_JOB_PLAN_3: 'Jobs Gold',

  HIRER_OFFER_PLAN_1: 'Offers Basic',

  HIRER_OFFER_PLAN_2: 'Offers Standard',

  HIRER_OFFER_PLAN_3: 'Offers Gold',

  HIRER_CV_PLAN_1: 'CV Basic',

  HIRER_CV_PLAN_2: 'CV Standard',

  HIRER_CV_PLAN_3: 'CV Gold'
};
const convertToYearly = (monthlyVal) => {
  switch (monthlyVal) {
    case '₹ 10k - 25k':
    case '₹10k - 25k':
      return '₹ 1.2L - 3L';
    case '₹ 25k - 50k':
    case '₹25k - 50k':
      return '₹ 3L - 6L';
    case '₹ 50k - 75k':
      return '₹ 6L - 9L';
    case '₹ 75k - 100k':
      return '₹ 9L - 12L';
    case '₹ 100k - 150k':
      return '₹ 12L - 18L';
    case '₹ 150k - 200k':
      return '₹ 18L - 24L';
    case '₹ 200k - 300k':
      return '₹ 24L - 36L';
    case '₹ 300k - 500k':
      return '₹ 36L - 60L';
    case '>  ₹ 500k':
      return '> ₹ 60L';
    default:
      return monthlyVal;
  }
};
const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
const getTimeIn12Hours = (date) => {
  const hour = date.getHours();
  return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
    date.getMinutes()
  )} ${hour > 12 ? 'PM' : 'AM'}`;
};
const formatDate = (date, variation = 1) =>
  `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
    date.getMonth() + 1
  )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

const formatTime = (time) => {
  const date = new Date(time);

  return getTimeIn12Hours(date) + ', ' + formatDate(date);
};
export default function (props) {
  const prevQuery = useRef(null);
  const prevPage = useRef(null);
  const classes = useStyles();
  const openUserPage = (userId, social) => {
    props.history.push(`/account/${userId}${social ? '?type=SOCIAL' : ''}`);
  };
  const openJobPage = (offerId) => {
    props.history.push(`/job/${offerId}`);
  };
  const [editModalData, setEditModalData] = useState(null);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(
    sessionStorage.getItem('viewAllJobPage')
      ? parseInt(sessionStorage.getItem('viewAllJobPage'), 10)
      : 1
  );
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(
    localStorage.getItem('viewMode') || 'grid'
  );
  const [candidates, setCandidates] = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const [lastPage, setLastPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 9
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [campaignType, setCampaignType] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [campaignLink, setCampaignLink] = useState('');
  const [campaignLinkWeb, setCampaignLinkWeb] = useState('');
  const [error, setError] = useState('');
  const [loading2, setLoading2] = useState(false); // Manage loading state
  const [campaignTypes, setCampaignTypes] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState('all');

  const handleOpenModal = (rowData) => {
    setSelectedJob(rowData); // Assuming `setSelectedJob` is defined to keep track of the current job
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const addNewCampaign = (jobOfferId, campaignType, campaignName) => {
    if (loading2 || !campaignName || !campaignType) return;
    if (campaignName.search(/^[a-zA-Z0-9-_]+$/) == -1) {
      alert("Can have only 'A-Z','a-z','_' and '-' in campaign name");
      return;
    }
    setLoading2(true);
    let url = '/marketing_campaign/add_campaign';
    sendHttpCall(
      'POST',
      url,
      {
        marketing_campaign_name: `${campaignType}-${campaignName}`,
        marketing_campaign_redirect_link: 'jobs',
        param_id: jobOfferId,
        mode: 'JOBS'
      },
      props
    )
      .then((response) => {
        if (response.state === 200) {
          setCampaignLink(response.data.campaignLink);
          setCampaignLinkWeb(response.data.campaignLinkWeb);
          setLoading2(false);
          setError('');
          showToast('Campaign created successfully');
        } else {
          setLoading2(false);
          setError(response.message);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading2(false);
        setError('Failed to create campaign');
      });
  };

  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    localStorage.setItem('viewMode', mode);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem('viewMode');
    if (savedMode) {
      setViewMode(savedMode);
    }
  }, []);

  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const handleChange = (event, value) => {
    setPage(value);
  };

  // const fetchUser8Data = async (offerId) => {
  //   return new Promise((resolve, reject) => {
  //     Axios.get(`${esocialUrl}/getuser8?offerid=${offerId}`)
  //       .then((res) => {
  //         resolve(res);
  //       })
  //       .catch((e) => {
  //         console.error('Error in addUserDefinedValueToMasterDBApi', e.response);
  //         reject(e);
  //       });
  //   });
  // };
  // const getData = () => {
  //   let pageVal = prevQuery.current !== query ? 1 : page;
  //   prevQuery.current = query;
  //   prevPage.current = pageVal;
  //   setLoading(true);
  //   let url = `/jobs/get_all_active_jobs?limit=${10}&page=${pageVal}`;
  //   if (query) {
  //     url += '&query=' + query;
  //   }
  //   sendHttpCall('GET', url, null, props).then((response) => {
  //     console.log(response);
  //     response.data.forEach(d => {
  //       fetchUser8Data(d.offerId).then((resp) => {
  //         console.log(resp);
  //         d['potentialuser8email'] = resp.data
  //         console.log(response.data)
  //         setData(response.data);
  //       });
  //     });
  //     let count = response.totalCount;
  //     if (page !== pageVal) setPage(pageVal);
  //     setTotalCount(count);
  //     setLastPage(Math.ceil(count / 10));
  //     setLoading(false);
  //   });
  // };

  const getData = async () => {
    let pageVal = page;

    // const savedPage = sessionStorage.getItem('viewAllJobPage');
    // let pageVal = prevQuery.current !== query ? 1 : savedPage ? parseInt(savedPage, 10) : page;

    //? old code
    // let pageVal = prevQuery.current !== query ? 1 : page;
    // prevQuery.current = query;
    // prevPage.current = pageVal;

    setLoading(true);

    let url = `/jobs/get_all_active_jobs?limit=${limit}&page=${pageVal}`;
    if (query) {
      url += '&query=' + query;
    }

    try {
      const response = await sendHttpCall('GET', url, null, props);
      console.log(response);

      // Using Promise.all to wait for all asynchronous operations to complete
      const updatedData = await Promise.all(
        response.data.map(async (d) => {
          // Spread operator to avoid direct mutation of the data
          return { ...d };
        })
      );

      // Update the state once after all data is processed
      setData(updatedData);
      setTotalCount(response.totalCount);
      setLastPage(Math.ceil(response.totalCount / 10));

      // Only update the page state if it has changed
      if (page !== pageVal) {
        setPage(pageVal);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error as appropriate for your application
    } finally {
      setLoading(false);
    }
  };

  const changeJobStatus = (offerId, approved, message, deleteJob) => {
    if (loading) return;
    if (!deleteJob && !approved && !message) {
      showToast('Cannot reject job without reason.');
      return;
    }
    console.log("delete", deleteJob)
    console.log("message", message)
    console.log("approved", approved)
    setLoading(true);
    let url = `/jobs/update_job_post_status`;
    sendHttpCall(
      'POST',
      url,
      {
        offerId,
        approved,
        message,
        delete: deleteJob
      },
      props
    ).then((response) => {
      console.log(response);
      setSelectedJob({});
      setSelectedJobForDelete({});
      if (deleteJob) showToast('Successfully deleted job post.');
      else
        showToast(
          approved
            ? 'Successfully approved job post.'
            : 'Successfully rejected job post.'
        );
      showToast(
        approved
          ? 'Successfully approved job post.'
          : 'Successfully rejected job post.'
      );
      setRejectReason(null);
      setRejectReasonCustom('');
      setLoading(false);
      setTimeout(getData, 500);
    });
  };
  const boostJob = (offerId) => {
    let url = `/jobs/boost_job`;
    sendHttpCall(
      'POST',
      url,
      {
        offerId
      },
      props
    ).then((response) => {
      showToast('Successfully boosted job post.');
    });
  };
  const toggleAutoRecruiter = async (offerId, activate) => {
    let url = `/jobs/toggle_auto_recruiter`;
    const response = await sendHttpCall(
      'POST',
      url,
      {
        offerId,
        activate
      },
      props
    );
    if (activate) showToast('Successfully turned on autorecruiter.');
    else showToast('Successfully turned off autorecruiter.');
  };
  const rejectJob = (offerId, reason) => {
    changeJobStatus(offerId, false, reason);
  };
  const deleteJob = (offerId) => changeJobStatus(offerId, null, null, true);

  //? old code
  // useEffect(() => {
  //   // sessionStorage.removeItem('viewClosedJobPage');
  //   if (prevPage.current !== page || prevQuery.current !== query) getData();
  // }, [page, query]);

  //changing the page
  useEffect(() => {
    getData();
    sessionStorage.setItem('viewAllJobPage', page);
    // sessionStorage.removeItem('viewAllJobPage');
  }, [page, limit]);

  // for query
  useEffect(() => {
    getData();
  }, [query]);

  const [goToPage, setGoToPage] = useState('');

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };
  const updateUnfulfillmentReason = async (offerId, reasonId) => {
    const url = '/jobs/update_unfulfillment_reason';
    try {
      const response = await sendHttpCall(
        'POST',
        url,
        { offerId, reason_id: reasonId },
        props
      );
      if (response.data === 'Status changed successfully.') {
        showToast(`Unfulfillment reason updated successfully.`);

        const updatedData = data.map((job) => {
          if (job.offerId === offerId) {
            return { ...job, unfulfillment_reason_id: reasonId };
          }
          return job;
        });
        setData(updatedData);
      } else {
        throw new Error('Failed to update reason');
      }
    } catch (error) {
      console.error('Error updating unfulfillment reason:', error);
      showToast(`Error updating reason: ${error.message}`);
    }
  };

  const handleGoToPage = () => {
    let pageNumber = parseInt(goToPage, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= lastPage) {
      setPage(pageNumber); // Assuming `onChange` accepts the target page number as its parameter
      setGoToPage('');
    } else {
      alert('Please enter a valid page number');
    }
  };

  useEffect(() => {
    getCampaignTypes();
  }, []);

  const handleRadioChange = (e) => {
    setSelectedJobType(e.target.value);
    if (e.target.value === 'goodspace') {
      setQuery("GOODSPACE");
    } else {
      setQuery(''); // Clear the query when 'All' is selected
    }
    setPage(1); // Reset to the first page
  };
  const getCampaignTypes = () => {
    setLoading2(true);
    let url = '/marketing_campaign/get_types';
    sendHttpCall('GET', url, null, props)
      .then((response) => {
        if (response.state === 200) {
          let options = response.data.map((a) => ({
            value: a.marketing_campaign_type,
            label: a.marketing_campaign_type
          }));
          setCampaignTypes(options);
          setLoading2(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching campaign types:', err);
        setLoading2(false);
      });
  };
  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    showToast('Campaign Link Copied');
  };
  const handleCampaignTypeChange = (selectedOption) => {
    if (selectedOption) {
      setCampaignType(selectedOption.value);
    } else {
      setCampaignType('');
    }
  };

  return (
    <div>
      <div className="active_jobs__container">
        <div className="active_jobs__header">
          <div className="active_jobs__title">Active Job Posts</div>

          <div className='active_jobs__filter'>
            <div className="active_jobs__search">
              <input
                type="text"
                placeholder="Search by user name/job title/company"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setQuery(e.target.value);
                    //set the page to 1 so that when the query is searched the user is navigated to the first page instead of remaining on the current page
                    setPage(1);
                  }
                }}
              />
              <Search />
            </div>
            <div className='active_jobs__filter_radio'>
              <label>
                <input
                  type="radio"
                  value="all"
                  checked={selectedJobType === 'all'}
                  onChange={handleRadioChange}
                />
                Jobs
              </label>
              <label>
                <input
                  type="radio"
                  value="goodspace"
                  checked={selectedJobType === 'goodspace'}
                  onChange={handleRadioChange}
                />
                Goodspace Jobs
              </label>
            </div>
          </div>
          <div className="view-toggle">
            <GridOn
              style={{
                cursor: 'pointer',
                color: viewMode === 'grid' ? '#297bca' : 'grey'
              }}
              onClick={() => handleViewModeChange('grid')}
            />
            <ViewAgenda
              style={{
                cursor: 'pointer',
                marginLeft: 20,
                color: viewMode === 'list' ? '#297bca' : 'grey'
              }}
              onClick={() => handleViewModeChange('list')}
            />
          </div>
        </div>
        <div className="active_jobs__posts-container">
          {loading ? (
            <div className="active_jobs__posts-loading">
              <Loader />
            </div>
          ) : viewMode === 'grid' ? (
            data.map((item) => (
              <JobCard
                status="ACTIVE"
                data={item}
                approveJob={null}
                rejectJob={rejectJob}
                currentPage={page}
                toggleAutoRecruiter={toggleAutoRecruiter}
                deleteJob={deleteJob}
                refreshData={getData}
                showToast={showToast}
                boostJob={boostJob}
              />
            ))
          ) : (
            <>
              <div className="listView">
                <MaterialTable
                  columns={[
                    {
                      title: 'S No.',
                      cellStyle: {
                        position: 'sticky',
                        left: 0,
                        backgroundColor: '#FFF'
                      },
                      headerStyle: {
                        position: 'sticky',
                        left: 0,
                        backgroundColor: '#FFF'
                      },
                      render: (rowData) =>
                        (page - 1) * 20 + rowData.tableData.id + 1
                    },
                    {
                      title: 'Title',
                      field: 'title',
                      render: (rowData) => {
                        return (
                          <div
                            style={{
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              openJobPage(rowData.offerId);
                            }}>
                            {rowData.title}
                          </div>
                        );
                      }
                    },
                    { title: 'Company', field: 'userInfo[0].organisation' },
                    {
                      title: 'Posted By',
                      field: 'name',
                      cellStyle: {
                        position: 'sticky',
                        left: 40,
                        backgroundColor: '#FFF'
                      },

                      headerStyle: {
                        position: 'sticky',
                        left: 40,
                        backgroundColor: '#FFF'
                      },
                      render: (rowData) => {
                        return (
                          <div
                            style={{
                              cursor: 'pointer',
                              // fontWeight: 500,
                              textTransform: 'capitalize',
                              // color: '#297bca'
                              // fontSize: '.85rem',
                              fontFamily: 'Roboto',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                            onClick={() => {
                              openUserPage(rowData.userId);
                            }}>
                            {rowData.name}
                          </div>
                        );
                      }
                    },
                    { title: 'Location', field: 'location_city' },
                    {
                      title: 'Applications',
                      field: 'applicationsCount',
                      cellStyle: {
                        width: 50,
                      },
                      headerStyle: {
                        width: 50,
                      },
                    },
                    { title: 'Shortlisted', field: 'shortlistedCount' },
                    // { title: 'Rejected', field: 'rejectedCount' },
                    { title: 'Interviewed', field: 'interviewedCount' },
                    { title: 'Approved By', field: 'adminName' },
                    { title: 'Assigned To', field: 'assignedToAdminName' },
                    {
                      title: 'Campaign Link',
                      field: 'internal_action',
                      sorting: false,
                      render: (rowData) => (
                        <LinkRounded
                          variant="contained"
                          color="primary"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleOpenModal(rowData)
                          }></LinkRounded>
                      )
                    },
                    {
                      title: 'Unfulfillment Reasons',
                      field: 'unfulfillment_reason_id',
                      render: (rowData) => (
                        <Select
                          label="Reason"
                          value={rowData.unfulfillment_reason_id}
                          onChange={(event) =>
                            updateUnfulfillmentReason(
                              rowData.offerId,
                              event.target.value
                            )
                          }
                          style={{ width: 200 }}>
                          <MenuItem value={1}>
                            Hiring closed using GoodSpace
                          </MenuItem>
                          <MenuItem value={2}>
                            Hiring closed internally
                          </MenuItem>
                          <MenuItem value={3}>Irrelevant Profiles</MenuItem>
                        </Select>
                      ),
                      editable: 'never'
                    }
                  ]}
                  data={data}
                  title="Active Job List View"
                  onChangePage={(newPage) => {
                    setPage(newPage + 1);
                  }}
                  onChangeRowsPerPage={(pageSize) => {
                    setLimit(pageSize);
                    setPage(1);
                  }}
                  totalCount={totalCount}
                  page={page - 1}
                  options={{
                    search: true,
                    paging: true,
                    pageSize: limit,
                    pageSizeOptions: [20, 50, 100, 250],
                    sorting: true,
                    exportButton: localStorage.getItem('user_type') === 'SALES' ? false : true,
                    actionsColumnIndex: -1,
                    rowStyle: (rowData) => ({
                      borderLeft:
                        rowData.shortlistedCount < 30 ? '5px solid red' : 'none'
                    })
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {viewMode === 'grid' && (
        <div className="active_jobs__pagination">
          <div className="active_jobs__pagination-count">
            Total Count : {totalCount}
          </div>
          <Pagination
            size="large"
            color="#297bca"
            count={lastPage}
            page={page}
            onChange={handleChange}
          />
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              size="small"
              label="Go to page"
              variant="outlined"
              value={goToPage}
              onChange={handleGoToPageChange}
              inputProps={{ type: 'number', min: 1, max: lastPage }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleGoToPage();
                }
              }}
            />
            <Button
              variant="contained"
              style={{ backgroundColor: '#297bca' }}
              onClick={handleGoToPage}>
              Go
            </Button>
          </Box>
        </div>
      )}
      {toastMessage && <div className="active_jobs__toast">{toastMessage}</div>}

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        className="active_jobs__modal-modal"
        overlayClassName="active_jobs__modal-overlay">
        <div className="addNewCampaign_title">Create Campaign</div>
        <div className="addNewCampaign_label">Select Campaign Type</div>
        <ReactSelect
          isLoading={loading}
          options={campaignTypes}
          onChange={handleCampaignTypeChange}
          style={{ fontFamily: 'Poppins' }}
          value={campaignTypes.find((ct) => ct.value === campaignType)}
        />

        <input
          type="text"
          placeholder="Campaign Name"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
        />
        <button
          onClick={() =>
            addNewCampaign(selectedJob.offerId, campaignType, campaignName)
          }>
          Generate Link
        </button>

        {loading2 ? (
          <div className="active_jobs__posts-loading2">
            <Loader />
          </div>
        ) : (
          campaignLinkWeb && (
            <>
              <div className="note-text">
                Click on the link box to copy the URL.
              </div>
              <div
                className="active_jobs__link-box"
                onClick={() => copyLink(campaignLink)}>
                <span className="active_jobs__link-text">{campaignLink}</span>
                <MuiLink className="active_jobs__link-icon" />
              </div>
              <div
                className="active_jobs__link-box"
                onClick={() => copyLink(campaignLinkWeb)}>
                <span className="active_jobs__link-text">
                  {campaignLinkWeb}
                </span>
                <MuiLink className="active_jobs__link-icon" />
              </div>
            </>
          )
        )}
        {error && <div className="campaign-error">{error}</div>}
      </ReactModal>
    </div>
  );
}
