import React, { useState, useEffect } from 'react';
import styles from './JobDetail.module.scss';
import { sendHttpCall } from 'shareService/httpService';
import Loader from 'components/Loader';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import SendIcon from '@material-ui/icons/Send';
import Select from 'react-select';
import { currencyOptions } from '../../config';

const JobDetail = (props) => {
  const offerId = props.match.params.offerId;
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentRemark, setCurrentRemark] = useState('');
  const [followUpDate, setFollowUpDate] = useState(new Date());
  const [noFollowUpNeeded, setNoFollowUpNeeded] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    fetchJobDetails(offerId);
  }, [offerId]);

  const fetchJobDetails = (offerId) => {
    setLoading(true);
    let url = `/jobs/preview?offerId=${offerId}`;
    sendHttpCall('GET', url, null, {}).then((response) => {
      if (response) {
        setJobData(response);
        setLoading(false);
      }
    });
  };
  const getLocaleTime = (time) => {
    if (time) return moment(new Date(time)).format('DD/MM/YYYY h:mm A');
  };

  const addRemarkToOffer = () => {
    const now = new Date();
    const minFollowUpTime = new Date(now.getTime() + 15 * 60000); // 15 mins

    if (!currentRemark.trim()) {
      showToast('Remark should not be empty.', 'error');
      return;
    }

    if (!noFollowUpNeeded && (!followUpDate || followUpDate <= minFollowUpTime)) {
      showToast('Please select a follow-up date and time at least 15 minutes from now.', 'error');
      return;
    }

    let url = 'cs/offer_remark';
    sendHttpCall('POST', url, {
      offerId: offerId,
      remark: currentRemark,
      nextFollowUpTime: noFollowUpNeeded ? null : followUpDate
    }, props)
      .then((response) => {
        setCurrentRemark('');
        setFollowUpDate(new Date());
        setNoFollowUpNeeded(false);
        showToast('Remark saved successfully!', 'success');
        fetchJobDetails(offerId); // Refetch the data
      })
      .catch((error) => {
        showToast('Failed to save remark', 'error');
      });
  };

  const showToast = (msg, type = 'success') => {
    setToastMessage({ msg, type });
    setTimeout(() => setToastMessage(''), 2000);
  };

  const renderRemarks = () => {
    if (!jobData || !jobData.offer_remarks.length) return null;

    return (
      <div className="remarks__container">
        <div className="remarks-header">Conversation History</div>
        {jobData.offer_remarks
          .filter((remark) => remark.remark)
          .map((remark, index) => (
            <div key={index} className="remark__item">
              <div className="remark__timestamp">
                {getLocaleTime(remark.date)}
              </div>
              <div className="remark__admin-name">
                {remark.adminName ? <>{remark.adminName}</> : 'Sales Team'}
              </div>
              <div className="remark__text">{remark.remark}</div>
              {remark.nextFollowUpAt && (
                <div className="next-follow-up">
                  Next Follow-up on: <span className="next-follow-up-date">{getLocaleTime(remark.nextFollowUpAt)}</span>
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };

  return (
    <>
      {loading && (
        <div className="active_jobs__posts-loading">
          <Loader />
        </div>
      )}

      {!loading && jobData && (
        <div className={styles.container}>
          <h1 className={styles.title}>Job Details</h1>
          <p className={styles.detail}><strong>Title:</strong> {jobData.title}</p>
          {jobData.userInfo && <p className={styles.detail}><strong>Posted By: </strong> {jobData.userInfo.name}</p>}
          <div className={styles.pfp}><img src={jobData.userInfo.image_id} alt={jobData.userInfo.name} /></div>
          <p className={styles.detail}><strong>Company: </strong> {jobData.companyName}</p>
          <p className={styles.detail}><strong> Compensation: </strong> {jobData.displayCompensation}</p>
          {jobData.location_city && <p className={styles.detail}><strong> Location: </strong> {jobData.location_city}</p>}
          <p className={styles.detail}><strong>Work Experience: </strong> {jobData.lowerworkex}-{jobData.upperworkex}</p>
          <p className={styles.detail}><strong>Type of Work: </strong> {jobData.offer_type}</p>
          {jobData.skills.length > 0 && (
            <div className={styles.skillCards}><strong>Skills:</strong>
              {jobData.skills?.map((item, index) => <div key={index} className={styles.skillCard}>{item.skill}</div>)}
            </div>
          )}
          <div className={styles.detail}><strong>Description:</strong>
            <div className={styles.value}>{jobData.description}</div>
          </div>
          <div className={styles.detail}><strong>Qualification Required: </strong>{jobData.highest_qualification_required}</div>
          {jobData?.screening_questions.length > 0  && (
            <div className={styles.detail}><strong>Screening Questions:</strong>
              {jobData.screening_questions?.map((question, index) => <div key={index} className={styles.question}>{question}</div>)}
            </div>
          )}
          {renderRemarks()}
          <div className="remark-input-container">
            <label className="remark__admin-name" htmlFor="remarkInput">
              {localStorage.getItem('user_name')} ({localStorage.getItem('user_email')})
            </label>
            <div className="remark-input-box">
              <textarea id="remarkInput" name="remark" value={currentRemark} onChange={(e) => setCurrentRemark(e.target.value)} className="remark-input" />
              <button onClick={addRemarkToOffer} className="send-button"><SendIcon style={{ color: '#297bca' }} /></button>
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                margin="normal"
                id="follow-up-date-picker"
                label="Next Follow-Up Date & Time"
                format="MM/dd/yyyy HH:mm"
                value={followUpDate}
                onChange={(date) => setFollowUpDate(date)}
                disabled={noFollowUpNeeded}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                InputLabelProps={{ className: 'large-label' }}
              />
            </MuiPickersUtilsProvider>
            <div className="no-follow-up-checkbox">
              <input type="checkbox" id="noFollowUpNeeded" checked={noFollowUpNeeded} onChange={(e) => setNoFollowUpNeeded(e.target.checked)} />
              <label htmlFor="noFollowUpNeeded">No follow-up needed</label>
            </div>
          </div>
        </div>
      )}

      {toastMessage && (
        <div className={`toast ${toastMessage.type}`}>{toastMessage.msg}</div>
      )}
    </>
  );
};

export default JobDetail;
