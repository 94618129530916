import React, { useState, useEffect } from 'react';
import { useLeadDrawer } from '../../../contexts/LeadDrawerContext';
import {
  Drawer,
  IconButton,
  Button,
  TextField,
  Typography,
  Snackbar,
  FormControlLabel,
  Checkbox,
  Grid
} from '@material-ui/core';
import moment from 'moment';
import { Close, ContactPhone, AlternateEmail } from '@material-ui/icons';
import MuiAlert from '@mui/material/Alert';
import Select from 'react-select';
import { salesStages , salesIntents} from '../../../config';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import { sendHttpCall } from 'shareService/httpService';
import styles from '../design.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LeadDrawer = (props) => {
  const { isOpen, leadData, closeDrawer } = useLeadDrawer();
  const [newRemark, setNewRemark] = useState('');
  const [nextFollowUpAt, setNextFollowUpAt] = useState(null);
  const [salesStage, setSalesStage] = useState(null);
  const [salesIntent, setSalesIntent] = useState(null);
  const [expectedRevenue, setExpectedRevenue] = useState('');
  const [noFollowUpNeeded, setNoFollowUpNeeded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [salesAdmins, setSalesAdmin] = useState([]);
  const [assignedToId, setAssignedToId] = useState(null);

  const getLocaleTime = (time) => {
    if (time) return moment(new Date(time)).format('DD/MM/YYYY h:mm A');
  };

  useEffect(() => {
    getSalesAdmins();
    if (leadData) {
      setNewRemark(leadData.remark || '');
      setNextFollowUpAt(leadData.nextFollowUpAt || null);
      setSalesStage(leadData.salesStage || null);
      setSalesIntent(leadData.salesIntent || null);
      setExpectedRevenue(leadData.expectedRevenue || '');
      setAssignedToId(leadData.assignedToId || '');
    }
  }, [leadData]);

  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const getSalesAdmins = () => {
    let url = `admin/users/SALES`;
    sendHttpCall('GET', url, null, props)
      .then((response) => {
        const formattedAdmins = [
          ...response.data.map((admin) => ({
            label: admin.name,
            value: admin.admin_id
          }))
        ];
        setSalesAdmin(formattedAdmins);
      })
      .catch((error) => {
        console.log('Error fetching sales admins:', error);
        showSnackbar('Failed to fetch sales admins', 'error');
      });
  };
  const updateLead = async () => {
    if (!leadData) return;

    let updatedData = {};
    if (salesStage && salesStage !== leadData.salesStage)
      updatedData.salesStage = salesStage;
    if (salesIntent && salesIntent !== leadData.salesIntent)
      updatedData.salesIntent = salesIntent;
    if (expectedRevenue && expectedRevenue !== leadData.expectedRevenue)
      updatedData.expectedRevenue = expectedRevenue;
    if (newRemark) updatedData.remark = newRemark;
    if (assignedToId && assignedToId !== leadData.assignedToId){
      updatedData.assignedToId = assignedToId;
      const assignedToName = salesAdmins.find(
        (option) => option.value === assignedToId
      ).label
      updatedData.assignedToName = assignedToName;
    }
    if (noFollowUpNeeded) {
      updatedData.nextFollowUpAt = null;
      if (
        Object.keys(updatedData).length === 0 ||
        (Object.keys(updatedData).length === 1 &&
          updatedData.nextFollowUpAt === null)
      ) {
        showSnackbar('No updates to save', 'info');
        return;
      }
    } else if (nextFollowUpAt) {
      if (new Date(nextFollowUpAt) - new Date() < 2 * 60 * 1000) {
        showSnackbar(
          'Follow-up date must be at least 2 minutes from now',
          'warning'
        );
        return;
      } else {
        updatedData.nextFollowUpAt = nextFollowUpAt;
      }
    } else if (!nextFollowUpAt && !noFollowUpNeeded) {
      showSnackbar(
        'Please set a follow-up date or mark as no follow-up needed',
        'warning'
      );
      return;
    }
    console.log('THIS IS UPDATED DATA', updatedData);
    if (Object.keys(updatedData).length === 0) {
      showSnackbar('No details to update', 'warning');
    } else {
      try {
        await sendHttpCall(
          'POST',
          `sales/update_lead`,
          { _id: leadData._id, ...updatedData },
          props
        );
        showSnackbar('Lead updated successfully', 'success');
        closeDrawer();
        setNoFollowUpNeeded(false);
      } catch (error) {
        console.error('Error updating lead:', error);
        showSnackbar('Failed to update lead', 'error');
      }
    }
  };
  const handleExpectedRevenueChange = (e) => {
    setExpectedRevenue(e.target.value);
  };

  const handleSalesStageChange = (selectedOption) => {
    setSalesStage(selectedOption);
  };
  const handleSalesIntentChange = (selectedOption) => {
    setSalesIntent(selectedOption);
  };

  const handleAssignToChange = (option) => {
    setAssignedToId(option.value);
  };
  const renderRemarks = () => {
    const reversedRemarks = [...leadData.remarks].reverse();

    return (
      <div className="remarks__container">
        <div className="remarks-header">Conversation History</div>
        {reversedRemarks.map((remark) => (
          <div key={remark._id} className="remark__item">
            <div className="remark__timestamp">
            {getLocaleTime(remark.createdAt)}
            </div>
            <div className="remark__admin-name">
              {remark.admin.adminName ? remark.admin.adminName : 'Sales Team'}
            </div>
            <div className="remark__text">{remark.text}</div>
            {remark.nextFollowUpAt && (
              <div className="next-follow-up">
                Next Follow-up on:{' '}
                <span className="next-follow-up-date">
                  {getLocaleTime(remark.nextFollowUpAt)}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      className={styles.container}
      ModalProps={{
        BackdropProps: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
          }
        }
      }}>
      <div className={styles.drawerContent}>
        <IconButton onClick={closeDrawer}>
          <Close />
        </IconButton>
        {leadData && (
          <div>
            <Typography variant="h4" style={{ paddingBottom: '16px' }}>
              <div className={styles.labelText}>Client Name</div>
              <div>{leadData.name}</div>
            </Typography>
            <Typography variant="h4" style={{ paddingBottom: '16px' }}>
              <div className={styles.labelText}>Contact Details</div>
              {leadData.mobileNumber && (
                <div className={styles.labelContent}>
                  
                  <ContactPhone />:
                  <a href={`https://api.whatsapp.com/send/?phone=${leadData.mobileNumber}&text&type=phone_number&app_absent=0`}> {leadData.mobileNumber}</a>
                </div>
              )}
              {leadData.email && (
                <div className={styles.labelContent}>
                  <AlternateEmail />: {leadData.email}
                </div>
              )}
            </Typography>
            <Typography variant="h4" style={{ paddingBottom: '2px' }}>
              <div className={styles.labelText}>
                Assigned By: {leadData.assignedBy.adminName} || 
                Created By: {leadData.createdBy.adminName}
              </div>
            </Typography>
            <Typography variant="h4" style={{ paddingBottom: '8px' }}>
              <div className={styles.labelText}> 
                Source: {leadData.source}
              </div>
            </Typography>
            <div className={styles.inputGroup}>
              <label>Assign To</label>
              <Select
                value={salesAdmins.find(
                  (option) => option.value === assignedToId
                )}
                onChange={handleAssignToChange}
                options={salesAdmins}
                placeholder="Select Sales Stage"
              />
            </div>
            <div className={styles.inputGroup}>
              <label>Expected Revenue</label>
              <input
                type="number"
                value={expectedRevenue}
                onChange={handleExpectedRevenueChange}
              />
            </div>
            <div className={styles.inputGroup}>
              <label>Sales Stage</label>
              <Select
                value={salesStage}
                onChange={handleSalesStageChange}
                options={salesStages}
                placeholder="Select Sales Stage"
              />
            </div>
            <div className={styles.inputGroup}>
              <label>Sales Intent</label>
              <Select
                value={salesIntent}
                onChange={handleSalesIntentChange}
                options={salesIntents}
                placeholder="Select Sales Intent"
              />
            </div>
            {leadData.remarks && leadData.remarks.length > 0 && renderRemarks()}

            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    margin="normal"
                    id="next-follow-up-date-picker"
                    label="Next Follow-Up Date & Time"
                    format="dd/MM/yyyy HH:mm"
                    value={nextFollowUpAt}
                    onChange={setNextFollowUpAt}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    disabled={noFollowUpNeeded} // Disable if no follow-up is needed
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={noFollowUpNeeded}
                      onChange={(e) => setNoFollowUpNeeded(e.target.checked)}
                    />
                  }
                  label="No follow-up needed"
                />
              </Grid>
            </Grid>

            <div className="remark-input-container">
              <Typography variant="body2" color="textSecondary" component="p">
                Add Conversation
              </Typography>
              <div className="remark-input-box">
                <TextField
                  multiline
                  rows={2}
                  value={newRemark}
                  onChange={(e) => setNewRemark(e.target.value)}
                  className="remark-input"
                  variant="outlined"
                />
              </div>
            </div>

            <div className={styles.addBtn}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => updateLead()}
                className={styles.saveButton}>
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}>
        <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
      </Snackbar>
    </Drawer>
  );
};

export default LeadDrawer;
